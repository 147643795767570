module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5e04d89c14c0268cbf6b3915ee8f8a10"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-9F4FZ6ZXVX"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":3600},"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["en","fr","pt"],"defaultLanguage":"en","siteUrl":"https://www.rankingpt.com","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/seoanalysis/","getLanguageFromPath":true},{"matchPath":"/:lang?/index/","getLanguageFromPath":true},{"matchPath":"/:lang?/custom-ecommerce-development/","getLanguageFromPath":true},{"matchPath":"/:lang?/web-development/","getLanguageFromPath":true},{"matchPath":"/:lang?/hosting-migration/","getLanguageFromPath":true},{"matchPath":"/:lang?/website-maintenance-packages/","getLanguageFromPath":true},{"matchPath":"/:lang?/wordpress-maintenance-packages/","getLanguageFromPath":true},{"matchPath":"/:lang?/Ecommerce-SEO/","getLanguageFromPath":true},{"matchPath":"/:lang?/International-SEO/","getLanguageFromPath":true},{"matchPath":"/:lang?/local-seo/","getLanguageFromPath":true},{"matchPath":"/:lang?/tiktok-ads/","getLanguageFromPath":true},{"matchPath":"/:lang?/facebook-ads/","getLanguageFromPath":true},{"matchPath":"/:lang?/google-ads/","getLanguageFromPath":true},{"matchPath":"/:lang?/instagram-ads/","getLanguageFromPath":true},{"matchPath":"/:lang?/youtube-ads/","getLanguageFromPath":true},{"matchPath":"/:lang?/blue-check-instagram-facebook-tiktok/","getLanguageFromPath":true}],"redirect":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
